/* Custom Fonts */
@font-face {
  font-family: RobotoBlack;
  src: url(./assets/fonts/Roboto/Roboto-Black.ttf);
}

@font-face {
  font-family: RobotoRegular;
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: RobotoMedium;
  src: url(./assets/fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: RobotoThin;
  src: url(./assets/fonts/Roboto/Roboto-Thin.ttf);
}

@font-face {
  font-family: RobotoLight;
  src: url(./assets/fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: cdciconfont;
  src: url(./assets/fonts/cdciconfont.woff2);
}

/* Global */

/* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */

body {
  margin: 0;
  padding: 0;
  font-family: RobotoRegular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: RobotoRegular;
}

a {
  font-family: RobotoRegular;
  color: #005eaa;
  text-decoration: underline;
}

a:hover,
span:hover {
  text-decoration: underline;
}

p,
span {
  font-family: RobotoRegular;
  font-size: 1.2rem;
  margin: 0 0 0 0;
}

.roboto-black {
  font-family: RobotoBlack;
}

.roboto-regular {
  font-family: RobotoRegular;
}

.roboto-medium {
  font-family: RobotoMedium;
}

.roboto-thin {
  font-family: RobotoThin;
}

.roboto-light {
  font-family: RobotoLight;
}

.white-bg {
  background-color: white !important;
}

.round-edge-small {
  border-radius: 0.5rem !important;
}

.overflow-scroll {
  overflow-y: scroll !important;
}

.no-overflow {
  overflow: hidden !important;
}

.ui.card > :last-child,
.ui.cards > .card > :last-child {
  border-radius: 0.5rem !important;
}

.ui.button:focus {
  outline: 2px solid #7aacfe !important;
  outline: -webkit-focus-ring-color auto 1px !important;
}

.ui.sidebar {
  overflow-y: inherit !important;
}

.ui.card p > a {
  color: #254f79;
}
