.cdc-nav {
  width: 100vw;
}

.cdc-nav .ui.menu.cdc-nav-menu {
  margin: 0;
  padding: 0.2rem 0.5rem;
  background-color: #005eaa;
  border: none;
  border-radius: 0;
}

.cdc-nav .ui.menu.ddt-nav {
  margin: 0;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 0;
  height: 100%;
}
/* Sidebar Header */
div.cdc-nav-header {
  display: flex;
}

.ui.grid.header-grid {
  height: 100%;
  margin: 0;
  color: #ffffff;
  min-height: 0;
  flex: 1;
  flex-basis: auto;
  width: 28%;
  display: flex;
}

.ui.grid.header-grid .row {
  padding: 0;
}

.ui.grid.header-grid .column.wide.five.header-img-column,
.ui.grid.header-grid .column.wide.eleven.header-text-column {
  padding: 0;
}

.ui.grid.ndpp-title {
  height: 100%;
  width: 100%;
  margin: 0;
  color: black;
}

.ui.grid.ndpp-title .row .column.sixteen.wide h2 {
  color: #ffffff;
  margin: 0;
  font-size: 2.5rem;
}

.ui.grid.ndpp-title .row .column.sixteen.wide h1 {
  color: #ffffff;
  margin: 0;
  font-size: 1.5rem;
}

.ui.grid.cdc-title .row .column.sixteen.wide h2 {
  color: #333333;
  margin: 0;
  font-size: 2rem;
}

.ui.grid.cdc-title .row .column.sixteen.wide h1 {
  color: #333333;
  margin: 0;
  font-size: 1.5rem;
}

div.program-search {
  width: 25%;
}

div.cdc-nav-page {
  display: flex;
  align-items: center;
  margin-left: auto;
}

div.cdc-nav-page .page-name {
  color: #ffffff;
}
