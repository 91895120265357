.app-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 3rem;
}

.app-container .app-section-title {
  background-color: rgba(221, 221, 221, 0.7);
  margin-bottom: 1rem;
  letter-spacing: 0.4em;
}

.app-container .app-section-title h3 {
  text-align: center;
  text-transform: uppercase;
  font-family: RobotoLight;
  color: #374658;
  font-size: 2.75rem;
}

.app-container .avail-apps {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1;
  flex-basis: auto;
  min-height: 0;
  max-height: 70vh;
  overflow-y: auto;
  padding: 0.5rem;
}

.ui.grid.module-wrapper {
  width: 100%;
  margin: 0 0.5rem;
  flex: 1;
  flex-basis: auto;
}

.ui.grid.module-wrapper .row .column {
  display: flex;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}

@media (min-width: 320px) and (max-width: 768px) {
  .app-container {
    height: 100%;
    align-self: center;
    overflow: auto;
  }

  .app-container .avail-apps {
    overflow-y: auto;
  }

  .ui.stackable.three.column.grid.module-wrapper {
    margin: 0 !important;
  }
}
