.ui.grid.message-helppage {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  /* margin-left: 5rem; */
  /* padding-top: 55%; /*34rem !important; /* padding from top the the entire brower windows area */
  /* padding-left: 1rem; */
  background: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  align-content: top;
  overflow-y: auto;
  overflow-x: auto; /* needed if zoom in */
  height: 85vh; /* height of the inner scroll container */
  width: 1300px !important;
  min-width: 1300px !important;
}

h3.ui.header {
  font-size: 24px;
}

h4.ui.header {
  font-size: 24px;
}

h5.ui.header {
  font-size: 16.8px;
}

h6.ui.header {
  font-size: 16.8px;
}
