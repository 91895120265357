.ui.grid.message-powerbipage {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  /* margin-left: 5rem; */
  /* padding-top: 34rem !important; powerbi */
  /* padding-left: 1rem; */
  background: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  align-content: center;
  overflow-y: auto;
  /* height: 850px; powerbi */
  width: 1300px !important;
  min-width: 1300px !important;
}
