.main {
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
  background: url('../assets/images/ddt-landing-page.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
