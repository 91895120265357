div.cdc-footer {
  padding: 1rem;
  background-color: #333333;
  min-height: 240px;
  margin-bottom: -0.5rem;
  width: 100%;
}

.ui.grid.main-grid {
  width: 90%;
  margin: 0 auto;
  color: #fff;
}

ul.link-list {
  height: 100%;
  list-style: none;
  font-size: 13px;
  padding: 0;
  margin: 0;
  text-align: left;
}

ul.link-list:before {
  content: attr(aria-label);
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  font-family: 'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif';
  /* font-family: RobotoRegular !important */
  /* Lato,'Helvetica Neue',Arial,Helvetica,sans-serif */
}

ul.link-list-questions {
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 13px;
}

ul.link-list-questions:before {
  content: attr(aria-label);
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  font-family: 'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif';
  /* font-family: RobotoRegular !important */
  /* Lato,'Helvetica Neue',Arial,Helvetica,sans-serif */
}

.social-media1 a {
  color: inherit;
}

ul.link-list li {
  line-height: 26px;
}

ul.link-list-questions li {
  line-height: 32px;
  display: flex;
  align-items: center;
}

ul.link-list li a,
ul.link-list li span {
  color: #ffffff;
  font-family: arial, sans-serif;
  font-size: 13px;
}

ul.link-list li a:hover,
ul.link-list li span:hover {
  text-decoration: underline;
}

ul.link-list-questions li a,
ul.link-list-questions li span {
  color: #ffffff;
  font-family: arial, sans-serif;
  font-size: 13px;
}

ul.link-list-questions li a:hover,
ul.link-list-questions li span:hover {
  text-decoration: underline;
}

ul.link-list-questions li span.fi {
  width: 24px;
  height: 24px;
  font-size: 1.6rem;
  line-height: 1;
  vertical-align: middle;
  text-decoration: none;
  margin: 0 0.5rem 0 0;
}

ul.link-list-questions li span.fi:hover {
  text-decoration: none;
}

.second-footer {
  background-color: #f0f0f0;
}

.second-footer a {
  color: black;
}

.second-footer a:hover {
  text-decoration: underline;
}

.second-footer-items {
  display: flex;
  color: inherit;
  max-width: 100px;
  justify-content: space-between;
}

.cdc-logo {
  width: 60%;
  padding: 10px;
}

.items {
  color: white;
}

ul.social-media1 {
  /* display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  max-width: 24px; */
  padding: 0;
  display: inline-block;
  margin-bottom: 3px;
  list-style-type: none;
}

ul.social-media1 li {
  float: left;
  margin-right: 10px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

[class*=' cdc-icon-']:before,
[class^='cdc-icon-']:before {
  font-family: cdciconfont;
  display: inline-block;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  /* speak: none; */
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  /* -moz-osx-font-smoothing: grayscale; */
}

a span.fi {
  width: 24px;
  height: 24px;
  font-size: 1.8rem;
  line-height: 1;
  vertical-align: middle;
}

li span.fi {
  width: 24px;
  height: 24px;
  font-size: 1.8rem;
  line-height: 1;
  vertical-align: middle;
}

.x24 {
  display: inline-block;
  vertical-align: middle;
}

.cdc-icon-fb-white:before {
  content: '\f1f9';
}

.cdc-icon-twitter-white:before {
  content: '\f203';
}

.cdc-icon-insta:before {
  content: '\f1dd';
}

.cdc-icon-linkedin-white:before {
  content: '\f1fd';
}

.cdc-icon-snapchat:before {
  content: '\f1e2';
}

.cdc-icon-youtube-white:before {
  content: '\f204';
}

.cdc-icon-syndication-white:before {
  content: '\f201';
}

.cdc-icon-rss-white:before {
  content: '\f200';
}

.cdc-icon-email:before {
  content: '\f23f';
}

.cdc-icon-cdc-tv-white:before {
  content: '\f1d9';
}

.cdc-icon-desktop:before {
  content: '\f3f3';
}

.cdc-icon-phone:before {
  content: '\f240';
}

.cdc-icon-email:before {
  content: '\f23f';
}

.cdc-icon-clock_01:before {
  content: '\f133';
}

@media (max-width: 480px) {
  div.cdc-footer {
    min-height: 140px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .ui.grid > .row > .column.cdc-footer-mobile {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
  }
}
