.ui.grid.message-carousel {
  width: 85%;
  height: 100%;
  display: flex;
  padding-top: 10rem;
  align-content: flex-end;
}

div.carousel-container {
  width: 100%;
  height: 100%;
  padding: 3rem;
  overflow-y: auto;
  max-height: 70vh;
}

.sixteen.wide.column .carousel-text h4 {
  color: #374658;
  font-size: 3.4rem;
}

.slick-slider {
  height: 100%;
}

.slick-slider ul.slick-dots {
  text-align: left;
}

.slick-dots li {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1rem;
}

.slick-dots li button:before {
  color: #374658;
  opacity: 0.3;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.slick-dots li.slick-active button:before {
  color: #374658;
  opacity: 1;
}

@media (min-width: 320px) and (max-width: 767px) {
  .ui.grid.message-carousel {
    height: 100%;
    align-self: center;
  }

  .sixteen.wide.column .carousel-text h5 {
    font-size: 2.25rem;
  }
}
