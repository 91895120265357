.card {
  height: 40%;
  width: 40%;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

  .card-title {
    font-size: 2rem;
    line-height: 10vh;
    padding: 0 5px;
    border-bottom: 1px solid #eee;
  }

  .card-description {
    height: 15vh;
    line-height: 5vh;
    padding: 10px 5px;
  }

  .card-media img {
    object-fit: cover;
    width: 100%;
    height: 45vh;
    &:hover {
      cursor: pointer;
    }
  }
}
