.App-header {
  display: flex;
  flex-direction: column;
  /* TODO: use this calc method for determining font sizes moving forward */
  font-size: calc(10px + 2vmin);
  flex: 1;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
}

.ui.grid.landing-page-container {
  width: 100%;
  margin: 0;
  flex: 1;
  flex-basis: auto;
}

.column.ten.wide.app-menu,
.column.six.wide.app-menu {
  display: flex;
}

@media (min-width: 320px) and (max-width: 768px) {
  .main {
    height: 100%;
  }
}
