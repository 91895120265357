.ui.card.module-card {
  width: 100%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  background-color: #dddddd;
}

.ui.card.module-card:hover {
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.5);
}

.ui.card.module-card .content {
  text-align: center;
  color: #374658;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.app-screenshot {
  height: 75%;
  width: 100%;
  border-top-left-radius: 0.285714rem;
  border-top-right-radius: 0.285714rem;
  overflow: hidden;
  background-size: cover;
}

.app-name {
  display: flex;
  flex: 1;
  flex-basis: auto;
  min-height: 0;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-bottom-left-radius: 0.285714rem;
  border-bottom-right-radius: 0.285714rem;
  width: 100%;
}

.app-name span {
  font-size: 1rem;
  font-weight: bold;
}

.ui.card.module-card .header {
  width: 100%;
}

@media (max-width: 768px) {
  .app-name span {
    display: block;
    width: 4rem;
    word-wrap: break-word;
    font-size: 10px;
  }
}
